import { template as template_319a7789069b48238b27ddb441b74389 } from "@ember/template-compiler";
const FKText = template_319a7789069b48238b27ddb441b74389(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
