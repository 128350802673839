import { template as template_c2829e41c17f4049af75ec84e4dbb6f4 } from "@ember/template-compiler";
const FKLabel = template_c2829e41c17f4049af75ec84e4dbb6f4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
